/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import ptBr from '@angular/common/locales/pt'; // Importa a localidade pt-BR
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr); // Registra a localidade pt-BR globalmente

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
