import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { SessionService } from '../../services/shared/session.service';
import { CommonModule } from '@angular/common';
import { StrapiService } from '../../services/integration/strapi/telas.service';
import { dockerEnvironment } from '../../environments/environment';

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [RouterOutlet, RouterModule, CommonModule],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.css'
})
export class SidenavComponent implements OnInit {

  enviromentStrapi = dockerEnvironment.apiUrlStrapi+'/admin'
  userLogado: any
  constructor(protected tokenService: SessionService, protected strapiService: StrapiService, protected router: Router)  {

  }

  
  ngOnInit(): void {
    this.tokenService.userLogado.subscribe(value => {
       this.userLogado = value
        
    })
    // this.strapiService.login().subscribe( value =>{
    //   window.localStorage.setItem('jwtToken', `"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzE1NjIwNDk4LCJleHAiOjE3MTgyMTI0OTh9.Fek5ERBmLD3ijViohbMmIO5O0o3ij4PNmjkYu16ny5Q"`);


    // })
  }


  openCmsInNewTab(): void {
    const environmentStrapi = this.enviromentStrapi
    window.open(environmentStrapi, '_blank');
  }

}


