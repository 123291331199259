import { HttpInterceptorFn, HttpHeaders } from "@angular/common/http";

export const loggerInterceptor: HttpInterceptorFn = (req, next) => {
  // Defina os endpoints que não devem usar o Authorization
  const excludedEndpoints = [
    '/authenticate',
    'https://notapremiadaacreanacmsdevsefazac.lampp-it.com.br',
    'follow-up',
    'user/register',
    'institution/register',
    'http://hml.sefaz.ac.gov.br/sefaz-api/v1/pdf/donwload/',
    'https://servicebus2.caixa.gov.br/portaldeloterias/api/federa',
    'noticia',
    'https://notapremiadaacreanacmsdevsefazac.lampp-it.com.br',
    'https://notapremiadaacreanahomsefazac.lampp-it.com.br',
    'https://notapremiadaacreana.ac.gov.br/api/',
    'https://notapremiadaacreana-hml.fazenda.local/api',  
    'admin/login'
  ];

  // Verifica se a URL da requisição está na lista de endpoints excluídos
  const isExcluded = excludedEndpoints.some(endpoint => req.url.includes(endpoint));

  if (isExcluded) {
    // Se for um endpoint excluído, passe a requisição sem adicionar o header de Authorization
    return next(req);
  } else if (req.url.includes('/admin/users')) {
    // Se for '/admin/users', use o jwtToken como Authorization e defina headers específicos
    const jwtToken = typeof localStorage !== 'undefined' && localStorage.getItem("jwtToken");
    if (jwtToken) {
      const headers = new HttpHeaders({
        'Accept': '*/*',
        'Authorization': 'Bearer ' + jwtToken.slice(1, -1),
        'Content-Type': 'application/json'
      });
      const authReq = req.clone({ headers });
      return next(authReq);
    } else {
      // Se não houver jwtToken, passe a requisição original
      return next(req);
    }
  } else if (typeof localStorage !== 'undefined' && localStorage.getItem("AccessToken")) {
    // Se não for um endpoint excluído, e houver um AccessToken no localStorage, clone a requisição com o header de Authorization
    const authReq = req.clone({
      headers: req.headers.set("Authorization", `Bearer ${localStorage.getItem("AccessToken")}`)
    });
    return next(authReq);
  } else {
    // Se não houver AccessToken, passe a requisição original
    return next(req);
  }
};