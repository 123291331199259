import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MarkdownModule } from 'ngx-markdown';
import { provideToastr } from 'ngx-toastr';
import { routes } from './app.routes';
import { loggerInterceptor } from './core/interceptors/token-interceptor';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';  // Importa a localidade pt-br do Angular

// Registra a localidade pt-br globalmente
registerLocaleData(ptBr);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([loggerInterceptor])),
    importProvidersFrom(MarkdownModule.forRoot()),
    provideToastr({
      positionClass: 'toast-top-right',
      maxOpened: 1,
      preventDuplicates: true
    }),
    provideAnimations(),
    {
      provide: LOCALE_ID, useValue: 'pt-BR'  // Define a localidade globalmente
    }
  ],
};
