<div id="backofficeId" class="container-fluid" style="background-color: whitesmoke;">
  <div class="row flex-nowrap">

    <!-- Sidebar -->
    <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 d-none d-md-block" style="background-color: #198754;">
      <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <a routerLink="/home-usuario"
          class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <img width="150" height="150" src="/assets/banners-home/logo_nota_white.png" alt="banner-home" />
        </a>
        <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start text-white"
          id="menu">
          <li>
            <a class="nav-link px-0 align-middle">
              <i class="fs-4 bi-house text-white" style="cursor: pointer;"></i>
              <span class="ms-1 d-none d-sm-inline text-white" routerLink="/backoffice/home"
                style="cursor: pointer;">Home</span>
            </a>
          </li>
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Gerenciamento de Solicitações" ||
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a class="nav-link px-0 align-middle">
              <i class="fs-4 bi bi-bar-chart-steps text-white" style="cursor: pointer;"></i>
              <span class="ms-1 d-none d-sm-inline text-white" routerLink="backoffice/listar-solicitacoes"
                style="cursor: pointer;">Solicitações Entidade </span>
            </a>
          </li>
          }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Super Admin" ){
          <li>
            <a href="#submenu4" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-gear text-white"> </i>
              <span class="ms-1 d-none d-sm-inline text-white">Parâmetros</span>
            </a>
            <ul class="collapse nav flex-column ms-1" id="submenu4" data-bs-parent="#menu">
              <li class="w-100">
                <a class="nav-link px-0" style="cursor: pointer;" routerLink="/backoffice/listar-sorteios">
                  <span class="d-none d-sm-inline text-white" style="margin-left: 10%;">Sorteios</span>
                </a>
              </li>
              <li class="w-100">
                <a class="nav-link px-0" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/listar-email"
                    style="margin-left: 10%;">Email</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/listar-rateios"
                    style="margin-left: 10%;">Rateios</span>
                </a>
              </li>
            </ul>
          </li>
          }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a href="#submenu5" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-tools text-white"></i>
              <span class="ms-1 d-none d-sm-inline text-white">Manutenção</span>
            </a>
            <ul class="collapse nav flex-column ms-1" id="submenu5" data-bs-parent="#menu">
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/listar-manutencao-sorteios"
                    style="margin-left: 10%;">Sorteio</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/participantes-entidades-sociais"
                    style="margin-left: 10%;">Entidades</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/participantes-pessoa-fisica"
                    style="margin-left: 10%;">Pessoa Fisica</span>
                </a>
              </li>
            </ul>
          </li>
          }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a class="nav-link px-0 align-middle" routerLink="/backoffice/rotinas">
              <i class="fs-4 bi-repeat text-white" style="cursor: pointer;"></i>
              <span class="ms-1 d-none d-sm-inline text-white" style="cursor: pointer;">Rotinas</span>
            </a>
          </li>
          }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Gerenciamento de Solicitações" ||
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a href="#submenu6" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-at text-white"></i>
              <span class="ms-1 d-none d-sm-inline text-white">Comunicação</span>
            </a>
            <ul class="collapse nav flex-column ms-1" id="submenu6" data-bs-parent="#menu">
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/enviar-email">Enviar Email</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/comunicacao/premios-pf">Premiar
                    Pessoa Física</span>
                </a>
              </li>
              <li>
                <a class="nav-link px-0 text-white" style="cursor: pointer;">
                  <span class="d-none d-sm-inline text-white" routerLink="/backoffice/comunicacao/premios-es">Premiar
                    Entidade</span>
                </a>
              </li>
            </ul>

          </li>
          }

          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Gerenciamento de conteúdo" ||
          tokenService.userLogado.getValue().profile == "Super Admin" || tokenService.userLogado.getValue().profile ==
          "Gerenciamento de Solicitações"){
          <li>
            <a class="nav-link px-0 align-middle" (click)="openCmsInNewTab()" target="_blank">
              <i class="fs-4 bi bi-newspaper text-white" style="cursor: pointer;"></i>
              <span class="ms-1 d-none d-sm-inline text-white" style="cursor: pointer;">Painel de Notícias</span>
            </a>
          </li>
          }
          @if ( tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a href="#submenu7" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-tools text-white"></i>
              <span class="ms-1 d-none d-sm-inline text-white" routerLink="/backoffice/gerenciar-permissoes">Gerenciar
                Permissões</span>
            </a>
          </li>
          }
        </ul>
        <hr>
      </div>
    </div>

    <!-- Mobile -->
    <div class="offcanvas offcanvas-start custom-offcanvas" tabindex="-1" id="offcanvasMenu"
      aria-labelledby="offcanvasMenuLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasMenuLabel">Menu</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="nav nav-pills flex-column mb-0 align-items-start">
          <li>
            <a class="nav-link text-white" routerLink="/backoffice/home" data-bs-dismiss="offcanvas">
              <i class="bi bi-house"> Home </i>
            </a>
          </li>
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Gerenciamento de Solicitações" ||
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a class="nav-link text-white" routerLink="backoffice/listar-solicitacoes" data-bs-dismiss="offcanvas">
              <i class="bi bi-bar-chart-steps"> Solicitações Entidade </i> 
            </a>
          </li>
          }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Super Admin" ){
          <li>
            <a href="#submenu4" data-bs-toggle="collapse" class="nav-link align-middle">
              <i class="bi-gear text-white"> Parâmetros </i> 
            </a>
            <ul class="collapse nav flex-column ms-1" id="submenu4" data-bs-parent="#menu">
              <li  style="cursor: pointer; margin-left: 15%;">
                <a class="nav-link text-white" routerLink="/backoffice/listar-sorteios" data-bs-dismiss="offcanvas"> Sorteios
                </a> 
              </li>
              <li style="cursor: pointer; margin-left: 15%;">
                <a class="nav-link text-white" routerLink="/backoffice/listar-email" data-bs-dismiss="offcanvas"> Email
                </a>
              </li>
              <li  style="cursor: pointer; margin-left: 15%;">
                <a class="nav-link text-white" routerLink="/backoffice/listar-rateios" data-bs-dismiss="offcanvas"> Rateios
                </a>
              </li>
            </ul>
          </li>
          }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a href="#submenu5" data-bs-toggle="collapse" class="nav-link align-middle">
              <i class="bi-tools text-white"> Manutenção </i> 
            </a>
            <ul class="collapse nav flex-column ms-1" id="submenu5" data-bs-parent="#menu">
              <li style="cursor: pointer; margin-left: 15%;">
                <a class="nav-link text-white" style="cursor: pointer;"
                  routerLink="/backoffice/listar-manutencao-sorteios" data-bs-dismiss="offcanvas"> Sorteio
                </a>
              </li>
              <li style="cursor: pointer; margin-left: 15%;">
                <a class="nav-link text-white" routerLink="/backoffice/participantes-entidades-sociais" data-bs-dismiss="offcanvas"> Entidades
                </a>
              </li>
              <li style="cursor: pointer; margin-left: 15%;">
                <a class="nav-link text-white" routerLink="/backoffice/participantes-pessoa-fisica" data-bs-dismiss="offcanvas"> Pessoa Física
                </a>
              </li>
            </ul>
          </li>
          }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a class="nav-link align-middle" routerLink="/backoffice/rotinas" data-bs-dismiss="offcanvas">
              <i class="bi-repeat text-white" style="cursor: pointer;"> Rotinas </i> 
            </a>
          </li>
          }
          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Gerenciamento de Solicitações" ||
          tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a href="#submenu6" data-bs-toggle="collapse" class="nav-link align-middle">
              <i class="bi-at text-white"> Comunicação </i> 
            </a>
            <ul class="collapse nav flex-column ms-1" id="submenu6" data-bs-parent="#menu">
              <li style="cursor: pointer; margin-left: 15%;">
                <a class="nav-link text-white" routerLink="/backoffice/enviar-email" data-bs-dismiss="offcanvas"> Enviar Email
                </a> 
              </li>
              <li style="cursor: pointer; margin-left: 15%;">
                <a class="nav-link text-white" routerLink="/backoffice/comunicacao/premios-pf" data-bs-dismiss="offcanvas"> Premiar Pessoa Física
                </a> 
              </li>
              <li style="cursor: pointer; margin-left: 15%;">
                <a class="nav-link text-white" routerLink="/backoffice/comunicacao/premios-es" data-bs-dismiss="offcanvas"> Premiar Entidade
                </a> 
              </li>
            </ul>

          </li>
          }

          @if (tokenService.userLogado.getValue().profile == "Gerenciamento de Operações" ||
          tokenService.userLogado.getValue().profile == "Gerenciamento de conteúdo" ||
          tokenService.userLogado.getValue().profile == "Super Admin" || tokenService.userLogado.getValue().profile ==
          "Gerenciamento de Solicitações"){
          <li>
            <a class="nav-link align-middle" (click)="openCmsInNewTab()" target="_blank">
              <i class="bi bi-newspaper text-white" style="cursor: pointer;" data-bs-dismiss="offcanvas"> Painel de Notícias </i> 
            </a>
          </li>
          }
          @if ( tokenService.userLogado.getValue().profile == "Super Admin"){
          <li>
            <a href="#submenu7" data-bs-toggle="collapse" class="nav-link align-middle">
              <i class="bi-tools text-white" routerLink="/backoffice/gerenciar-permissoes" data-bs-dismiss="offcanvas"> Gerenciar
                Permissões </i> 
            </a>
          </li>
          }
        </ul>
      </div>
    </div>

    <!-- Conteúdo Principal -->
    <div class="col py-3 router-content">

      <div class="d-flex align-items-center p-3 position-relative">
        <button class="btn btn-success d-md-none" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
          <i class="bi bi-list"></i>
        </button>

        <div class="dropdown ms-auto">
          <a href="#" class="d-flex align-items-center text-black text-decoration-none" id="dropdownUser1"
            data-bs-toggle="dropdown" aria-expanded="false">

            <i class="bi bi-person-circle fs-1 d-md-none"></i>

            <span class="d-none d-md-inline mx-1">
              <i class="bi bi-person-circle" style="margin-right: 5px;"></i>
              {{tokenService.userLogado.getValue().name}}
            </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
            <li><a class="dropdown-item" routerLink="/home-usuario">Voltar para home</a></li>
          </ul>
        </div>
      </div>

      <router-outlet></router-outlet>
    </div>
  </div>
</div>